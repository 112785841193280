/* ----------------------------------------------
 * reCaptchaへの問い合わせ機能を管理するモジュール
 *
 * このファイルで、サイトキーや、htmlに付記するreCaptchaの<scrpt>も挿入する
---------------------------------------------- */
// module exports
export { ReCaptchaModule };

class ReCaptchaModule {
  /* 定数 ------------------------------------- */
  AJAX_STATE_DONE = 4;
  AJAX_RESPONSE_DONE = 200;

  /* デフォルト設定 ------------------------------------- */
  DEFAULT_CONFIG = {
    // reCaptchaのコンパネで取得するキー、「HTMLコードで使用します」と書いてある方
    reCaptchaSiteKey: "6LdrvXEcAAAAAFXPRIYwnoC6fskx_xcupOFtZm16",

    // reCaptcha問い合わせ用のPHP側ファイルへのパス
    // reCaptchaのコンパネで取得するシークレットキー(サイトと reCAPTCHA 間の通信で使用しますと書いてある方)は、isHuman.phpに記載する
    reCaptchaPhpFile: "/form/libs/isHuman.php",
  };

  /**
   * コンストラクター
   *
   * @param {Object} config 初期値を使用しない場合の設定
   * @returns {Object} オブジェクト
   */
  constructor(config = {}) {
    //設定をマージ
    this.config = Object.assign({}, this.DEFAULT_CONFIG, JSON.parse(JSON.stringify(config)));

    // DomにrecaptchaAPIスクリプトを挿入する
    this.insertReCaptchaScript();

    return this;
  }

  /**
   * DomにrecaptchaAPIスクリプトを挿入する
   */
  insertReCaptchaScript(){
    // DOMにreCaptchaAPIのスクリプトを挿入
    var recaptchaDom = document.createElement('script');
    recaptchaDom.src = 'https://www.google.com/recaptcha/api.js?render='+this.config.reCaptchaSiteKey;
    document.body.appendChild(recaptchaDom);
  }

  /**
   * トークン取得、bot判定実行まで一気通貫で処理する関数
   * @return array {'status':isHuman.phpが発行するエラーコード,'isHuman':bot判定}
   */
  run() {
    return this.stepMng();
  }

  /**
   * 処理ステップを管理
   * 多重非同期を走らせるが、await付加での実行ができるのはトップレイヤーの関数のみのため
   * @return array {'status':isHuman.phpが発行するエラーコード,'isHuman':bot判定}
   */
  async stepMng(){
    // reCapthca トークンを取得
    const token = await this.getToken();

    // 取得したトークンを引数として、isHuman.php実行
    const result = await this.getIsHuman(token);

    return result;
  }

  /**
   * reCaptchaトークンを取得して返す
   * @return string token recaptchaAPIが発行するトークン文字列
   */
  async getToken(){
    const _self = this;
    // APIへの非同期処理
    return grecaptcha.execute(_self.config.reCaptchaSiteKey, { action: "sent" }).then(function (token) {
      return token;
    });
  }

  /**
   * isHuman.phpをAJAXで実行
   * @param _token recaptchaAPIが発行し、getTokenで取得した文字列トークン
   * @return array {'status':isHuman.phpが発行するエラーコード,'isHuman':bot判定}
   */
  async getIsHuman(_token){
    const _self = this;

    return new Promise((resolve, reject) => {
      let _ajax = new XMLHttpRequest();
      _ajax.open("GET", this.config.reCaptchaPhpFile + "?recaptchaResponse=" + _token, true);
      _ajax.onload = function (e) {
        console.log(" - AJAX 通信開始 -");

        // AJAX通信が完了していなければ終了
        if (_ajax.readyState != _self.AJAX_STATE_DONE) return;

        // AJAX通信でレスポンスがきていなければ、AJX通信エラーとして告知、終了
        if (_ajax.status != _self.AJAX_RESPONSE_DONE) {
          console.log(" - AJAX通信エラー発生 -");
          reject({"status":"XX-001 AJAXエラー"});
          return;
        }

        console.log(" - AJAX通信正常完了 -");
        try {
          // AJAXのレスポンスをパースしてresolve
          const _response = JSON.parse(_ajax.responseText);
          console.log(" isHumanレスポンス : ",_response);
          resolve(_response);
        }
        catch(e){
          // パースエラーの場合は告知、reject
          reject({"status":"XX-002 PARSEエラー"});
        }
      };
      _ajax.onerror = function (e) {
        // XMLHttpRequestの実行時エラー
        // 構文エラー、パラメータエラーなど
        console.log("++ AJAX ERROR ++");
        console.log(_ajax.statusText);
      };
      _ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      // 引数のトークンをそのまま使用
      _ajax.send();
    });
  }

  /**
   * モジュールを除去する。
   */
  destroy() {}
}
