'use strict';

import "./Modernizr/modernizr-custom";

/* ==========================================================================
 * custom theme template
 *
 * Modernizr
 *
========================================================================== */

/* ブラウザー判定関数の追加
---------------------------------------------- */
// UA文字列を確保
var ua = new String(window.navigator.userAgent.toLowerCase());

// ブラウザ判定のための文字列検索
if(ua.indexOf('edge') != -1 || ua.indexOf('edg') != -1) {
  Modernizr.browser = 'Microsoft Edge';
} else if(ua.indexOf('trident') != -1 || ua.indexOf('msie') != -1) {
  Modernizr.browser = 'Microsoft Internet Explorer';
} else if(ua.indexOf('opr') != -1 || ua.indexOf('opera') != -1) {
  Modernizr.browser = 'Opera';
} else if(ua.indexOf('chrome') != -1) {
  Modernizr.browser = 'Google Chrome';
} else if(ua.indexOf('firefox') != -1) {
  Modernizr.browser = 'FireFox';
} else if(ua.indexOf('safari') != -1) {
  Modernizr.browser = 'Safari';
} else {
  Modernizr.browser = 'Unknown';
}

// htmlへブラウザーclassを追加
var root = document.getElementsByTagName( 'html' )[0];
let rootClass = Modernizr.browser.replace(' ','-');
root.classList.add(rootClass);

// UA判定文字列検索関数
ua.has = function (cond) {
    return this.indexOf(cond) != -1;
};

// ブラウザー判定
Modernizr.browserDetection = {
  'edge' : function () {
    return modernizr.browser == 'Microsoft Edge';
  },
  'msie' : function () {
    return modernizr.browser == 'Microsoft Internet Explorer';
  },
  'chrome' : function () {
    return modernizr.browser == 'Google Chrome';
   },
  'safari' : function () {
    return modernizr.browser == 'Safari';
   },
  'firefox' : function () {
    return modernizr.browser == 'FireFox';
   },
  'opera' : function () {
    return modernizr.browser == 'Opera';
   }
};

// デバイス判定のための文字列検索
var iphone = ua.has('iphone');
var ipod = ua.has('ipod');
var ipad = ua.has('ipad');
var macosx = ua.has('mac os x');
var android = ua.has('android');
var androidPhone = android && ua.has('mobile');
var androidTablet = android && ! ua.has('mobile');
var windows = ua.has('windows');
var windowsPhone = windows && ua.has('phone');
var windowsTablet = windows && ua.has('touch');

Modernizr.deviceDetection = {
    'ios' : function () { return iphone || ipod || ipad; },
    'iphone' : function () { return iphone; },
    'ipod' : function () { return ipod; },
    'ipad' : function () { return ipad; },
    'macosx' : function () { return macosx; },
    'android' : function () { return android; },
    'androidPhone' : function () { return androidPhone; },
    'androidTablet' : function () { return androidTablet; },
    'windows' : function () { return windows; },
    'windowsPhone' : function () { return windowsPhone; },
    'windowsTablet' : function () { return windowsTablet; },
};

const modernizr = Modernizr;
window.modernizr = Modernizr;

export { modernizr };
