/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-audio-contextmenu-cookies-cssanimations-csscalc-customevent-eventlistener-flexbox-forcetouch-history-svg-touchevents-webp-webpalpha-webpanimation-webplossless_webp_lossless-domprefixes-hasevent-mq-prefixes-testallprops-testprop-teststyles !*/
!(function (e, n, t) {
  function o(e, n) {
    return typeof e === n;
  }
  function r() {
    var e, n, t, r, a, i, s;
    for (var A in w)
      if (w.hasOwnProperty(A)) {
        if (
          ((e = []),
          (n = w[A]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (r = o(n.fn, 'function') ? n.fn() : n.fn, a = 0; a < e.length; a++)
          (i = e[a]),
            (s = i.split('.')),
            1 === s.length
              ? (Modernizr[s[0]] = r)
              : (!Modernizr[s[0]] ||
                  Modernizr[s[0]] instanceof Boolean ||
                  (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                (Modernizr[s[0]][s[1]] = r)),
            x.push((r ? '' : 'no-') + s.join('-'));
      }
  }
  function a() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : S
      ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
      : n.createElement.apply(n, arguments);
  }
  function i() {
    var e = n.body;
    return e || ((e = a(S ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function s(e, t, o, r) {
    var s,
      A,
      l,
      u,
      c = 'modernizr',
      f = a('div'),
      d = i();
    if (parseInt(o, 10))
      for (; o--; )
        (l = a('div')), (l.id = r ? r[o] : c + (o + 1)), f.appendChild(l);
    return (
      (s = a('style')),
      (s.type = 'text/css'),
      (s.id = 's' + c),
      (d.fake ? d : f).appendChild(s),
      d.appendChild(f),
      s.styleSheet
        ? (s.styleSheet.cssText = e)
        : s.appendChild(n.createTextNode(e)),
      (f.id = c),
      d.fake &&
        ((d.style.background = ''),
        (d.style.overflow = 'hidden'),
        (u = B.style.overflow),
        (B.style.overflow = 'hidden'),
        B.appendChild(d)),
      (A = t(f, e)),
      d.fake
        ? (d.parentNode.removeChild(d), (B.style.overflow = u), B.offsetHeight)
        : f.parentNode.removeChild(f),
      !!A
    );
  }
  function A(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function l(e) {
    var n = B.className,
      t = Modernizr._config.classPrefix || '';
    if ((S && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var o = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(o, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((n += ' ' + t + e.join(' ' + t)),
      S ? (B.className.baseVal = n) : (B.className = n));
  }
  function u(e, n) {
    if ('object' == typeof e) for (var t in e) P(e, t) && u(t, e[t]);
    else {
      e = e.toLowerCase();
      var o = e.split('.'),
        r = Modernizr[o[0]];
      if ((2 == o.length && (r = r[o[1]]), 'undefined' != typeof r))
        return Modernizr;
      (n = 'function' == typeof n ? n() : n),
        1 == o.length
          ? (Modernizr[o[0]] = n)
          : (!Modernizr[o[0]] ||
              Modernizr[o[0]] instanceof Boolean ||
              (Modernizr[o[0]] = new Boolean(Modernizr[o[0]])),
            (Modernizr[o[0]][o[1]] = n)),
        l([(n && 0 != n ? '' : 'no-') + o.join('-')]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }
  function c(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function d(e, n, t) {
    var r;
    for (var a in e)
      if (e[a] in n)
        return t === !1
          ? e[a]
          : ((r = n[e[a]]), o(r, 'function') ? f(r, t || n) : r);
    return !1;
  }
  function p(n, t, o) {
    var r;
    if ('getComputedStyle' in e) {
      r = getComputedStyle.call(e, n, t);
      var a = e.console;
      if (null !== r) o && (r = r.getPropertyValue(o));
      else if (a) {
        var i = a.error ? 'error' : 'log';
        a[i].call(
          a,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate',
        );
      }
    } else r = !t && n.currentStyle && n.currentStyle[o];
    return r;
  }
  function m(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function v(n, o) {
    var r = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; r--; ) if (e.CSS.supports(m(n[r]), o)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var a = []; r--; ) a.push('(' + m(n[r]) + ':' + o + ')');
      return (
        (a = a.join(' or ')),
        s(
          '@supports (' + a + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' == p(e, null, 'position');
          },
        )
      );
    }
    return t;
  }
  function h(e, n, r, i) {
    function s() {
      u && (delete O.style, delete O.modElem);
    }
    if (((i = o(i, 'undefined') ? !1 : i), !o(r, 'undefined'))) {
      var l = v(e, r);
      if (!o(l, 'undefined')) return l;
    }
    for (
      var u, f, d, p, m, h = ['modernizr', 'tspan', 'samp'];
      !O.style && h.length;

    )
      (u = !0), (O.modElem = a(h.shift())), (O.style = O.modElem.style);
    for (d = e.length, f = 0; d > f; f++)
      if (
        ((p = e[f]),
        (m = O.style[p]),
        A(p, '-') && (p = c(p)),
        O.style[p] !== t)
      ) {
        if (i || o(r, 'undefined')) return s(), 'pfx' == n ? p : !0;
        try {
          O.style[p] = r;
        } catch (g) {}
        if (O.style[p] != m) return s(), 'pfx' == n ? p : !0;
      }
    return s(), !1;
  }
  function g(e, n, t, r, a) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      s = (e + ' ' + U.join(i + ' ') + i).split(' ');
    return o(n, 'string') || o(n, 'undefined')
      ? h(s, n, r, a)
      : ((s = (e + ' ' + C.join(i + ' ') + i).split(' ')), d(s, n, t));
  }
  function y(e, n, o) {
    return g(e, t, t, n, o);
  }
  var w = [],
    b = {
      _version: '3.6.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        w.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        w.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = b),
    (Modernizr = new Modernizr()),
    Modernizr.addTest('cookies', function () {
      try {
        n.cookie = 'cookietest=1';
        var e = -1 != n.cookie.indexOf('cookietest=');
        return (
          (n.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'), e
        );
      } catch (t) {
        return !1;
      }
    }),
    Modernizr.addTest(
      'customevent',
      'CustomEvent' in e && 'function' == typeof e.CustomEvent,
    ),
    Modernizr.addTest('eventlistener', 'addEventListener' in e),
    Modernizr.addTest('history', function () {
      var n = navigator.userAgent;
      return (-1 === n.indexOf('Android 2.') &&
        -1 === n.indexOf('Android 4.0')) ||
        -1 === n.indexOf('Mobile Safari') ||
        -1 !== n.indexOf('Chrome') ||
        -1 !== n.indexOf('Windows Phone') ||
        'file:' === location.protocol
        ? e.history && 'pushState' in e.history
        : !1;
    }),
    Modernizr.addTest(
      'svg',
      !!n.createElementNS &&
        !!n.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect,
    );
  var T = b._config.usePrefixes
    ? ' -webkit- -moz- -o- -ms- '.split(' ')
    : ['', ''];
  b._prefixes = T;
  var x = [],
    _ = 'Moz O ms Webkit',
    C = b._config.usePrefixes ? _.toLowerCase().split(' ') : [];
  b._domPrefixes = C;
  var B = n.documentElement;
  Modernizr.addTest(
    'contextmenu',
    'contextMenu' in B && 'HTMLMenuItemElement' in e,
  );
  var S = 'svg' === B.nodeName.toLowerCase(),
    E = (function () {
      function e(e, n) {
        var r;
        return e
          ? ((n && 'string' != typeof n) || (n = a(n || 'div')),
            (e = 'on' + e),
            (r = e in n),
            !r &&
              o &&
              (n.setAttribute || (n = a('div')),
              n.setAttribute(e, ''),
              (r = 'function' == typeof n[e]),
              n[e] !== t && (n[e] = t),
              n.removeAttribute(e)),
            r)
          : !1;
      }
      var o = !('onblur' in n.documentElement);
      return e;
    })();
  (b.hasEvent = E),
    Modernizr.addTest('audio', function () {
      var e = a('audio'),
        n = !1;
      try {
        (n = !!e.canPlayType),
          n &&
            ((n = new Boolean(n)),
            (n.ogg = e
              .canPlayType('audio/ogg; codecs="vorbis"')
              .replace(/^no$/, '')),
            (n.mp3 = e
              .canPlayType('audio/mpeg; codecs="mp3"')
              .replace(/^no$/, '')),
            (n.opus =
              e.canPlayType('audio/ogg; codecs="opus"') ||
              e.canPlayType('audio/webm; codecs="opus"').replace(/^no$/, '')),
            (n.wav = e
              .canPlayType('audio/wav; codecs="1"')
              .replace(/^no$/, '')),
            (n.m4a = (
              e.canPlayType('audio/x-m4a;') || e.canPlayType('audio/aac;')
            ).replace(/^no$/, '')));
      } catch (t) {}
      return n;
    }),
    Modernizr.addTest('csscalc', function () {
      var e = 'width:',
        n = 'calc(10px);',
        t = a('a');
      return (t.style.cssText = e + T.join(n + e)), !!t.style.length;
    });
  var Q = (function () {
    var n = e.matchMedia || e.msMatchMedia;
    return n
      ? function (e) {
          var t = n(e);
          return (t && t.matches) || !1;
        }
      : function (n) {
          var t = !1;
          return (
            s(
              '@media ' + n + ' { #modernizr { position: absolute; } }',
              function (n) {
                t =
                  'absolute' ==
                  (e.getComputedStyle
                    ? e.getComputedStyle(n, null)
                    : n.currentStyle
                  ).position;
              },
            ),
            t
          );
        };
  })();
  b.mq = Q;
  var R = (b.testStyles = s);
  Modernizr.addTest('touchevents', function () {
    var t;
    if ('ontouchstart' in e || (e.DocumentTouch && n instanceof DocumentTouch))
      t = !0;
    else {
      var o = [
        '@media (',
        T.join('touch-enabled),('),
        'heartz',
        ')',
        '{#modernizr{top:9px;position:absolute}}',
      ].join('');
      R(o, function (e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  });
  var U = b._config.usePrefixes ? _.split(' ') : [];
  b._cssomPrefixes = U;
  var P;
  !(function () {
    var e = {}.hasOwnProperty;
    P =
      o(e, 'undefined') || o(e.call, 'undefined')
        ? function (e, n) {
            return n in e && o(e.constructor.prototype[n], 'undefined');
          }
        : function (n, t) {
            return e.call(n, t);
          };
  })(),
    (b._l = {}),
    (b.on = function (e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function () {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (b._trigger = function (e, n) {
      if (this._l[e]) {
        var t = this._l[e];
        setTimeout(function () {
          var e, o;
          for (e = 0; e < t.length; e++) (o = t[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function () {
      b.addTest = u;
    }),
    Modernizr.addAsyncTest(function () {
      var e = new Image();
      (e.onerror = function () {
        u('webpalpha', !1, { aliases: ['webp-alpha'] });
      }),
        (e.onload = function () {
          u('webpalpha', 1 == e.width, { aliases: ['webp-alpha'] });
        }),
        (e.src =
          'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==');
    }),
    Modernizr.addAsyncTest(function () {
      var e = new Image();
      (e.onerror = function () {
        u('webpanimation', !1, { aliases: ['webp-animation'] });
      }),
        (e.onload = function () {
          u('webpanimation', 1 == e.width, { aliases: ['webp-animation'] });
        }),
        (e.src =
          'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA');
    }),
    Modernizr.addAsyncTest(function () {
      var e = new Image();
      (e.onerror = function () {
        u('webplossless', !1, { aliases: ['webp-lossless'] });
      }),
        (e.onload = function () {
          u('webplossless', 1 == e.width, { aliases: ['webp-lossless'] });
        }),
        (e.src =
          'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=');
    }),
    Modernizr.addAsyncTest(function () {
      function e(e, n, t) {
        function o(n) {
          var o = n && 'load' === n.type ? 1 == r.width : !1,
            a = 'webp' === e;
          u(e, a && o ? new Boolean(o) : o), t && t(n);
        }
        var r = new Image();
        (r.onerror = o), (r.onload = o), (r.src = n);
      }
      var n = [
          {
            uri: 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
            name: 'webp',
          },
          {
            uri: 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
            name: 'webp.alpha',
          },
          {
            uri: 'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
            name: 'webp.animation',
          },
          {
            uri: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
            name: 'webp.lossless',
          },
        ],
        t = n.shift();
      e(t.name, t.uri, function (t) {
        if (t && 'load' === t.type)
          for (var o = 0; o < n.length; o++) e(n[o].name, n[o].uri);
      });
    });
  var k = function (n) {
    var o,
      r = T.length,
      a = e.CSSRule;
    if ('undefined' == typeof a) return t;
    if (!n) return !1;
    if (
      ((n = n.replace(/^@/, '')),
      (o = n.replace(/-/g, '_').toUpperCase() + '_RULE'),
      o in a)
    )
      return '@' + n;
    for (var i = 0; r > i; i++) {
      var s = T[i],
        A = s.toUpperCase() + '_' + o;
      if (A in a) return '@-' + s.toLowerCase() + '-' + n;
    }
    return !1;
  };
  b.atRule = k;
  var D = { elem: a('modernizr') };
  Modernizr._q.push(function () {
    delete D.elem;
  });
  var O = { style: D.elem.style };
  Modernizr._q.unshift(function () {
    delete O.style;
  });
  b.testProp = function (e, n, o) {
    return h([e], t, n, o);
  };
  (b.testAllProps = g),
    (b.testAllProps = y),
    Modernizr.addTest('cssanimations', y('animationName', 'a', !0)),
    Modernizr.addTest('flexbox', y('flexBasis', '1px', !0));
  var J = (b.prefixed = function (e, n, t) {
    return 0 === e.indexOf('@')
      ? k(e)
      : (-1 != e.indexOf('-') && (e = c(e)), n ? g(e, n, t) : g(e, 'pfx'));
  });
  Modernizr.addTest('forcetouch', function () {
    return E(J('mouseforcewillbegin', e, !1), e)
      ? MouseEvent.WEBKIT_FORCE_AT_MOUSE_DOWN &&
          MouseEvent.WEBKIT_FORCE_AT_FORCE_MOUSE_DOWN
      : !1;
  }),
    r(),
    delete b.addTest,
    delete b.addAsyncTest;
  for (var M = 0; M < Modernizr._q.length; M++) Modernizr._q[M]();
  e.Modernizr = Modernizr;
})(window, document);
