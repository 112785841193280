/* ----------------------------------------------
 * フォームバリデーションjs
 *
---------------------------------------------- */
// module exports
export { FormValidationModule,Validator };


/**
 * フォームバリデーターモジュール
 *
 * フォームのバリデート処理を実行するコアプログラム
 */
class FormValidationModule {
  /* 定数 ------------------------------------- */
  /* デフォルト設定（本体） ------------------------------------- */
  DEFAULT_CONFIG = {
    // このプラグインで使用するカスタム属性
    pluginPrefix: "data-valid",

    // エラーブロックのクラス
    errorClass: "error-message",

    // エラーメッセージを表示するか
    displayErrorMessage: true,

    /**
     * サブミットボタンをロックするかどうか
     * true: バリデーションをクリアするまでsubmitボタンがロックされ、送信できない。
     * false: いつでも送信ボタンが押下できる。
     */
    useSubmitLock: true,

    /* プラグインが使用する各種属性値 ------------------------------------- */
    // form要素に指定する属性値
    attributeForm: /* ベース(pluginPrefixの値)+ */ "-form",

    // submitボタンに指定する属性値（ロック判定属性としても使用）
    attributeSubmitButton: /* ベース(pluginPrefixの値)+ */ "-submit",

    // ステップボタンに指定する属性値（ロック判定属性としても使用）
    attributeNextButton: /* ベース(pluginPrefixの値)+ */ "-next",
    attributeBackButton: /* ベース(pluginPrefixの値)+ */ "-back",

    // 現在のバリデートステータスを保持する属性値
    attributeValidateState: /* ベース(pluginPrefixの値)+ */ "-state",

    // Validity(各種バリデートエラーの個別情報)を保持する属性値
    attributeValidity: /* ベース(pluginPrefixの値)+ */ "-validity",

    // エラー表示のアウター要素であることを示す属性値
    attributeErrorOuterDom: /* ベース(pluginPrefixの値)+ */ "-outer",
  };

  /* プラグインが使用するイベント名 ------------------------------------- */
  EVENT_NAMES = {
    // バリデート実行時
    validate: 'validate'
  };

  /* メンバープロパティ ------------------------------------- */
  // オプション
  config = {};

  /**
   * コンストラクター
   *
   * @param {DOM} form バリデート対象にするフォーム要素
   * @param {Object} config プラグインの設定値
   * @param {Object} validator バリデータクラス
   *
   * @returns {Object} 自身のインスタンス
   */
  constructor(form, config, validator) {
    // 対象となるフォーム要素が存在しない場合は処理を停止
    if (typeof form !== 'object')
      return false;

    // 引数にバリデーターインスタンスがなければ、デフォルトのバリデータークラスを使用する。
    if (typeof validator === "object" && validator !== null) {
      this.validator = validator;
    } else {
      this.validator = new Validator();
    };

    // 包含classであるvalidatorのsuperに自身をセット
    this.validator.super = this;

    // configがなければ初期化
    if( typeof config !== 'object' )
      config = {};

    // グローバル設定をマージする。
    this._mergeConfigs(form,config);

    console.log("Form Validation:module init");

    // メインプロセススタート
    this._start();

    return this;
  }

  /**
   * ※private
   * ユーザー設定を初期設定へマージする。
   *
   * @param {object} config
   */
  _mergeConfigs(form,config) {
    //設定をマージ
    this.config = Object.assign(
      {},
      this.DEFAULT_CONFIG,
      JSON.parse(JSON.stringify(config))
    );

    // 定数を作成
    // プレフィックス定数
    this.PLUGIN_PREFIX = this.config.pluginPrefix;

    // フォーム属性定数
    this.ATTR_FORM = this.PLUGIN_PREFIX + this.config.attributeForm;

    // サブミットボタン属性定数
    this.ATTR_SUBMIT = this.PLUGIN_PREFIX + this.config.attributeSubmitButton;

    // ステップボタン属性定数
    this.ATTR_NEXT = this.PLUGIN_PREFIX + this.config.attributeNextButton;
    this.ATTR_BACK = this.PLUGIN_PREFIX + this.config.attributeBackButton;

    // バリデートタイプ定数
    this.ATTR_TYPE = this.PLUGIN_PREFIX + "-type";

    // state属性定数
    this.ATTR_STATE = this.PLUGIN_PREFIX + this.config.attributeValidateState;

    // エラー表示アウターボックス定数
    this.ATTR_OUTER = this.PLUGIN_PREFIX + this.config.attributeErrorOuterDom;
    // エラーCSSクラス
    this.ERROR_CLASS = this.config.errorClass;

    // バリデート状況記載属性定数
    this.ATTR_VALIDITY = this.PLUGIN_PREFIX + this.config.attributeValidity;

    // 要素の格納先
    // フォームのラッパー要素
    this.form = form;
    this.submit = null;
    this.next = null;
    this.back = null;
    this.items = null;

    // フォームの状態変数
    this.state = false;

    // 発火用イベントの作成
    this.events = {
      // バリデート実行時イベント
      validate: new Event(
        this.EVENT_NAMES.validate
        ,{bubbles: true, cancelable: false}
      )
    };

    // bindしたイベントコールバックを変数として保存する。
    // 個別バリデート実行イベント用処理
    this.callback_eventExecValidation = this._eventExecValidation.bind(this);
    // 全要素バリデート実行イベント用処理
    this.callback_eventExecValidationAll =
      this._eventExecValidationAll.bind(this);
  }

  /**
   * ※private
   * モジュールを実行
   * @returns {Boolean} モジュールの起動に失敗した場合、申し訳程度にfalseを返す
   */
  _start() {
    // DOMの初期化を行う
    this._initElements();

    // 送信ボタンの活性状態を変更
    this.setSubmitState();

    // イベントを設定
    this._switchEventListener();
  }

  /**
   * ※private
   * 対象DOM要素の初期化とDOMの確保を実行。
   */
  _initElements() {
    // フォーム内のバリデート対象要素をすべて取得
    this.items = this._pickUpInputElements();

    // get submit button:submitボタンを確保する
    this.submit = this.form.querySelectorAll(
      "*[" + this.ATTR_SUBMIT + "]"
    );

    // ステップボタンを確保する
    this.next = this.form.querySelectorAll(
      "*[" + this.ATTR_NEXT + "]"
    );
    this.back = this.form.querySelectorAll(
      "*[" + this.ATTR_BACK + "]"
    );
  }

  /**
   * ※private
   * バリデート対象となるinput要素の初期化とDOMの確保を実行。
   */
   _pickUpInputElements() {
    // フォーム内のバリデート対象要素をすべて取得
    var inputElements = [];
    inputElements = this.form.querySelectorAll("*[" + this.ATTR_TYPE + "]");

    // 対象要素を初期化して配列として返送する。
    return Array.from(inputElements).map(element => {
      // バリデート対象かどうかを判定
      if (!this.validator.isValidatableItem(element)) return;

      // 入力必須要素なら未検証ステートに変更
      if (this.validator.hasValidateAttribute(element, "required"))
        element.setAttribute(this.ATTR_STATE, "unvalid");

      // 対象要素を格納
      return element;
    })
    // undefinedを除去する。
    .filter(Boolean);
  }

  /**
   * ※private
   * DOMへイベントを設定する。
   */
  _switchEventListener(mode = "add") {
    const _self = this;

    // 各種input項目へのバリデート実行イベントの設定
    Array.from(_self.items).map((element) => {
      if (mode == "add") {
        // イベント付与モード
        element.addEventListener("change", this.callback_eventExecValidation);
        element.addEventListener("focusout", this.callback_eventExecValidation);
        element.addEventListener(
          "validation",
          this.callback_eventExecValidation
        );
      } else {
        // イベント除去モード
        element.removeEventListener(
          "change",
          this.callback_eventExecValidation
        );
        element.removeEventListener(
          "focusout",
          this.callback_eventExecValidation
        );
        element.removeEventListener(
          "validation",
          this.callback_eventExecValidation
        );
      }
    });

    // 全項目へのバリデート実行イベントの設定
    if (mode == "add") {
      // イベント付与モード
      _self.form.addEventListener(
        "validateAll",
        this.callback_eventExecValidationAll
      );
    } else {
      // イベント除去モード
      _self.form.removeEventListener(
        "validateAll",
        this.callback_eventExecValidationAll
      );
    }
  }

  /**
   * private:個別バリデート実行イベント用処理
   *
   * @param {Object} event
   */
  _eventExecValidation(event) {
    const target = event.target;

    // exec validation:バリデート実行
    this.executeValidation(target).then(() => {
      // エラーを表示
      this.displayValidationResults();
    });
  }

  /**
   * private:全要素バリデート実行イベント用処理
   *
   * @param {Object} event
   */
  _eventExecValidationAll(event) {
    console.log("Form Validation:start all validation");

    // exec validation all:バリデート全実行
    this.executeValidationAll().then(() => {
      // エラーを表示
      this.displayValidationResults();
    });
  }

  /* ----------------------------------------------
  * パブリックメソッド
  ---------------------------------------------- */
  /**
   * プラグインを除去する。
   */
  destroy() {
    console.log("Form Validation:destroy");

    // イベントリスナーを除去する。
    this._switchEventListener("remove");

    // submitボタンの挙動をリセットする。
    this.resetSubmitState();

    const _self = this;

    this.items.forEach((element) => {
      // 属性消去を全ての項目に実行
      _self.destroyState(element);
      _self.validator.destroyValidity(element);
    });
  }

  /* バリデート処理関連 ------------------------------------- */
  /**
   * 個別のバリデートを実行する。
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @returns {Promise} 結果を内包したプロミスオブジェクト
   */
  executeValidation(target) {
    const _self = this;

    // 非同期処理でバリデート
    return new Promise(function (resolve, reject) {
      // バリデート状態をリセット
      _self.resetValidation(target);

      // バリデートタイプを取得
      const validationType = target.getAttribute(_self.ATTR_TYPE);

      // 該当するバリデートタイプがあればバリデーションを実行
      if (typeof _self.validator[validationType] === "function") {
        // バリデータのバリデートルールメソッドを実行
        _self.validator[validationType](target);
      } else if (typeof _self.validator.default === "function") {
        // 該当するルールがなければデフォルトルールメソッドを実行
        _self.validator.default(target);
      }

      // バリデート実行イベントを発火
      target.dispatchEvent(_self.events.validate);

      resolve(true);
    });
  }

  /**
   * 全対象にバリデートを実行する。
   *
   * @returns {Promise} 結果を内包したプロミスオブジェクト
   */
  executeValidationAll() {
    const _self = this;

    // 全項目に非同期処理でバリデートを実行する。
    return Promise.all(
      Array.from(_self.items).map((element) => {
        return _self.executeValidation(element);
      })
    );
  }

  /**
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @returns -
   */
  resetValidation(target) {
    const _self = this;

    // バリデートステートを初期化
    // validityを初期化
    _self.validator.resetValidity(target);

    // エラーを非表示
    // エラーアウター要素を走査する。
    const targetOuter = target.closest("*[" + _self.ATTR_OUTER + "]");
    // エラーアウターがあればアウターを、なければターゲット要素のみを対象とする。
    if (targetOuter) {
      // アウター要素にマージ済みvalidityに準拠したエラーを表示する。
      _self.deleteError(targetOuter);
      // validityを初期化
      _self.validator.resetValidity(targetOuter);
    } else {
      _self.deleteError(target);
    }

    // バリデート状態を初期化
    target.removeAttribute(_self.ATTR_STATE);
  }

  /* バリデーション結果関連 ------------------------------------- */
  getValidationResult() {
    // 全要素にバリデートを実行する。
    return this.executeValidationAll().then(() => {
      // エラーを表示
      this.displayValidationResults();
    }).then(() => {
      return this.judgeSubmit();
    })
  }

  /* エラーメッセージ関連 ------------------------------------- */
  /**
   * バリデート結果に応じたエラーメッセージを表示する。
   *
   */
  displayValidationResults() {
    const _self = this;

    // 全要素のエラーステータスをエラーに反映する。
    Array.from(_self.items).forEach((element, i) => {
      // 対象が未検証であればスキップする（バリデートしない）
      if (!_self.validator.isValidatedItem(element)) return true;

      // 変数を準備する。
      let target = null,
        targetValidity = null,
        targets = null,
        validity = null;

      // ターゲット要素を格納
      target = element;
      // 対象要素のバリデート状態を取得する。
      targetValidity = _self.validator.getValidity(element);

      // エラーアウター要素を走査する。
      const targetOuter = element.closest("*[" + _self.ATTR_OUTER + "]");

      // エラーアウターがある場合
      if (targetOuter) {
        // outer要素の中に存在するすべてのバリデート対象、かつバリデート済の要素を走査
        const allElments = targetOuter.querySelectorAll(
          "*[" + _self.ATTR_TYPE + "]"
        );
        targets = Array.from(allElments).filter((element) => {
          if (
            _self.validator.isValidatableItem(element) &&
            _self.validator.isValidatedItem(element)
          )
            return element;
        });

        // validityを作成
        // エラー状況（validity）をエラー（false）を優先しつつマージし、エラー内容を統合する。
        let validities = [];
        Array.from(targets).forEach((element, i) => {
          validities.push(_self.validator.getValidity(element));
        });
        // マージしたvalidityを統合validityへ代入。
        validity = _self.validator.mergeValidity(validities);
      } else {
        // エラーアウターがない場合
        // ターゲットのvalidityを統合validityとして扱う。
        validity = targetValidity;
      }

      // エラーがあった場合
      if (validity.valid == false) {
        // エラー属性を設定する。
        // エラーアウターがある場合、アウター要素にマージ済みvalidityに準拠したエラー処理を行う。
        if (targetOuter) _self.setState(targetOuter, "invalid");

        // 対象要素にエラー処理を行う。
        if (targetValidity.valid == false) {
          _self.setState(target, "invalid");
        }

        // エラーメッセージを表示する設定になっていた場合エラーを表示
        if (_self.config.displayErrorMessage == true) {
          // エラーアウターがあればアウターを、なければターゲット要素のみを対象とする。
          if (targetOuter) {
            // アウター要素にマージ済みvalidityに準拠したエラーを表示する。
            _self.displayError(targetOuter, validity);
          } else {
            _self.displayError(target, targetValidity);
          }
        }
      }

      // エラーがない場合
      if (validity.valid == true) {
        // エラー属性を除去
        // エラーアウターがあればそちらも対象にする。
        if (targetOuter) _self.setState(targetOuter,'valid');
        _self.setState(target,'valid');

        // エラーメッセージを表示する設定になっていた場合エラーを除去
        if (_self.config.displayErrorMessage == true) {
          // エラーアウターがあればアウターを、なければターゲット要素のみを対象とする。
          if (targetOuter) {
            _self.deleteError(targetOuter);
          } else {
            _self.deleteError(target);
          }
        }
      }
    });

    // submitボタンの挙動制御
    _self.setSubmitState();
  }

  /**
   * 対象のvalidityに応じたエラーメッセージを表示
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @param {Object} validity 対象のバリデートステータス
   */
  displayError(target, validity) {
    const _self = this;

    // 既に表示されているエラーがあれば除去する
    this.deleteError(target);

    // カスタムエラー文言を作成して挿入
    let validationMessage = _self._createErrorMessageArray(validity);
    target.after(validationMessage);
  }

  /**
   * 対象のエラーメッセージを除去
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   */
  deleteError(target) {
    const _self = this;

    // 既に表示されているエラーがあれば除去する
    var errorMessage = target.nextElementSibling;
    if (errorMessage && errorMessage.classList.contains(_self.ERROR_CLASS))
      errorMessage.parentNode.removeChild(errorMessage);
  }

  /**
   * ※private
   * エラーメッセージのHTML要素を作成する。
   *
   * @param {Object} validity 対象要素のバリデートステータス
   * @returns {Dom Element} validityに応じたエラーメッセージのDOM要素
   */
  _createErrorMessageArray(validity = {}) {
    const _self = this;

    // カスタムエラー文言を作成
    let validationMessage = Object.keys(validity)
      .map((key) => {
        // エラー内容があればメッセージに追加
        if (validity[key] === true && key !== "valid") {
          if (typeof _self.validator.message[key] === "undefined") {
            // 対応するエラーがなければデフォルトエラーを追加
            return _self.validator.message["valid"];
          } else {
            // 対応するエラーがあればエラーを追加
            return _self.validator.message[key];
          }
        }
      })
      // undefinedを削除
      .filter(Boolean);

    // DOMを作成
    var message = document.createElement("div");
    // 複数エラーを改行でjoin
    message.innerHTML = validationMessage.join("<br>");
    message.className = _self.ERROR_CLASS;

    return message;
  }

  /* state属性関連 ------------------------------------- */
  /**
   * 対象にstate属性値をセットする。
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @param {String} state バリデート状況を表す文字列(valid/invalid)
   */
  setState(target, state = null) {
    // ターゲット要素のバリデートステート属性を変更
    target.setAttribute(this.ATTR_STATE, state);
  }

  /**
   * 対象のエラー属性を除去する。
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   */
  destroyState(target) {
    // ターゲット要素のバリデートステート属性を除去
    target.removeAttribute(this.ATTR_STATE);
  }

  /* 送信ボタンの制御関連 ------------------------------------- */
  /**
   * フォームの検証状況を精査し、送信可能かどうかを返す
   * @returns {Boolean}
   */
  judgeSubmit() {
    const _self = this;

    // submitボタンがなければ離脱
    if (!_self.submit) return;

    var invalidItems = Array.from(_self.items).filter((element) => {
      const state = element.getAttribute(_self.ATTR_STATE);

      // 未検証、またはエラーの項目のみを抽出
      if (state == "unvalid" || state == "invalid") return element;
    });

    // 未検証、エラー項目が一つでもあればfalseとする
    if (invalidItems.length == 0) {
      _self.state = true;
      return true;
    } else {
      _self.state = false;
      return false;
    }
  }

  /**
   * フォームが送信可能かどうかを判別してsubmitボタンの活性状態を変更
   * @returns {Boolean}
   */
  setSubmitState() {
    const _self = this;

    if (this.judgeSubmit()) {
      // バリデートが通っていればsubmitボタン属性にtrueを設定（活性化）
      // submitボタン
      Array.from(this.submit).filter((element) => {
        element.setAttribute(_self.ATTR_SUBMIT, "true");

        // サブミットボタンのロック設定がtrueならばボタンのdisabled属性を変更する。
        if (this.config.useSubmitLock == true)
          element.disabled = false;
      });
      // nextボタン
      Array.from(this.next).filter((element) => {
        element.setAttribute(_self.ATTR_NEXT, "true");

        // サブミットボタンのロック設定がtrueならばボタンのdisabled属性を変更する。
        if (this.config.useSubmitLock == true)
          element.disabled = false;
      });
      return true;

    } else {
      // バリデートが通っていなければfalseを設定（非活性化）
      // submitボタン
      Array.from(this.submit).filter((element) => {
        element.setAttribute(_self.ATTR_SUBMIT, "false");

        // サブミットボタンのロック設定がtrueならばボタンのdisabled属性を変更する。
        if (this.config.useSubmitLock == true)
          element.disabled = true;
      });
      // nextボタン
      Array.from(this.next).filter((element) => {
        element.setAttribute(_self.ATTR_NEXT, "false");

        // サブミットボタンのロック設定がtrueならばボタンのdisabled属性を変更する。
        if (this.config.useSubmitLock == true)
          element.disabled = true;
      });
      return false;
    }
  }

  /**
   * submitボタンの活性状態をリセット
   * @returns {Boolean}
   */
  resetSubmitState() {
    const _self = this;

    // submitボタンをリセット
    Array.from(_self.submit).filter((element) => {
      element.disabled = false;
      element.setAttribute(_self.ATTR_SUBMIT, "");
    });

    // nextボタンをリセット
    Array.from(_self.next).filter((element) => {
      element.disabled = false;
      element.setAttribute(_self.ATTR_NEXT, "");
    });
  }
}

/**
 * バリデーターモジュール
 *
 * 実際のバリデートを行うルーチン郡。
 * バリデート処理順序などのルール設定と個別処理を記載する。
 -------------------------------------------------------------------------- */
class Validator {
  /* 定数 ------------------------------------- */
  /* デフォルト設定（バリデーター） ------------------------------------- */
  // バリデーションステータス（バリデーションの状況の定義に使用する属性値）
  DEFAULT_VALIDITY = {
    badInput: false,
    notNumeric: false,
    customError: false,
    patternMismatch: false,
    mailMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valid: true,
    valueMissing: false,
    notEqual: false,
    notCheck: false,
    notSelect: false,
  };

  // バリデーションメッセージ（ステータスとkeyを揃えて対応するエラーメッセージを設定）
  DEFAULT_MESSAGE = {
    // 不完全な値だと判断した場合
    badInput: "入力内容が不適切です。",
    // 数字以外が入力されている場合
    notNumeric: "半角数字以外が入力されています。",
    // 独自エラーメッセージがセットされている場合
    customError: "入力内容に誤りがあります。",
    // pattern属性で指定した要件を満たさない場合
    patternMismatch: "入力内容が条件に適していません。",
    // メールの要件を満たさない場合
    mailMismatch: "メールアドレスとして不適切です。",
    // max、min属性で指定した最大値、最小値を超えている、満たない場合
    rangeOverflow: "入力値が大きすぎます。",
    rangeUnderflow: "入力値が小さすぎます。",
    // step属性で指定した規則に一致しない場合
    stepMismatch: "入力内容が条件に適していません。",
    // maxlength、minlength属性で指定した長さを超えているとき、満たない場合
    tooLong: "入力内容が長すぎます。",
    tooShort: "入力内容が短すぎます。",
    // 入力値が要件を満たさない場合
    typeMismatch: "入力内容が条件に適していません。",
    // 値の妥当性にひとつでも問題がある場合
    valid: "入力内容に誤りがあります。",
    // 必須なのに値がない場合
    valueMissing: "この項目は必須です。",
    // 対象項目と一致しない場合
    notEqual: "入力値が一致しません。",
    // チェックされていない場合
    notCheck: "この項目は必須です。",
    // チェックされていない場合
    notSelect: "この項目は必須です。",
  };

  /* メンバープロパティ ------------------------------------- */
  validity = {};
  message = {};

  /**
   * コンストラクター
   */
  constructor(config = {}) {
    //設定をマージ

    // validityキーが存在していなければ空で作成
    if (!("validity" in config)) config.validity = {};
    this.validity = Object.assign(
      {},
      this.DEFAULT_VALIDITY,
      JSON.parse(JSON.stringify(config.validity))
    );

    // messageキーが存在していなければ空で作成
    if (!("message" in config)) config.message = {};
    this.message = Object.assign(
      {},
      this.DEFAULT_MESSAGE,
      JSON.parse(JSON.stringify(config.message))
    );
  }

  /**
   * バリデートルール関数群
   * 定義した処理が上から順に適用される。
   */

  /**
   * デフォルトルール
   * @param {DOM element} target
   */
  default(target) {
    // required属性があった場合
    this._judgeAndExecuteValidation(target, "required");

    // has number:number属性だった場合
    if (target.getAttribute("type") === "number") this.number(target);

    // has min:min属性があった場合
    this._judgeAndExecuteValidation(target, "min");

    // has max:max属性があった場合
    this._judgeAndExecuteValidation(target, "max");

    // has minlength:minlength属性があった場合
    this._judgeAndExecuteValidation(target, "minlength");

    // has maxlength:maxlength属性があった場合
    this._judgeAndExecuteValidation(target, "maxlength");

    // has pattern:pattern属性があった場合
    this._judgeAndExecuteValidation(target, "pattern");

    // has equal:equal属性があった場合
    this._judgeAndExecuteValidation(target, "equal");

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * テキストルール
   * @param {DOM element} target
   */
  text(target) {
    // required属性があった場合
    this._judgeAndExecuteValidation(target, "required");

    // has minlength:minlength属性があった場合
    this._judgeAndExecuteValidation(target, "minlength");

    // has maxlength:maxlength属性があった場合
    this._judgeAndExecuteValidation(target, "maxlength");

    // has pattern:pattern属性があった場合
    this._judgeAndExecuteValidation(target, "pattern");

    // has equal:equal属性があった場合
    this._judgeAndExecuteValidation(target, "equal");

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * メールルール
   * @param {DOM element} target
   */
  email(target) {
    // required属性があった場合
    this._judgeAndExecuteValidation(target, "required");

    // 半角英数記号のみかをチェック（強制）
    this.halfWidth(target);

    // has minlength:minlength属性があった場合
    this._judgeAndExecuteValidation(target, "minlength");

    // has maxlength:maxlength属性があった場合
    this._judgeAndExecuteValidation(target, "maxlength");

    // has pattern:pattern属性があった場合
    this._judgeAndExecuteValidation(target, "pattern");

    // メールアドレスとして正しいかどうかをチェック（強制）
    this.emailpattern(target);

    // has equal:equal属性があった場合
    this._judgeAndExecuteValidation(target, "equal");

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * numericルール
   * @param {DOM element} target
   */
  numeric(target) {
    // required属性があった場合
    this._judgeAndExecuteValidation(target, "required");

    // 半角数字のみかチェック（強制）
    this.number(target);

    // has min:min属性があった場合
    this._judgeAndExecuteValidation(target, "min");

    // has max:max属性があった場合
    this._judgeAndExecuteValidation(target, "max");

    // has minlength:minlength属性があった場合
    this._judgeAndExecuteValidation(target, "minlength");

    // has maxlength:maxlength属性があった場合
    this._judgeAndExecuteValidation(target, "maxlength");

    // has pattern:pattern属性があった場合
    this._judgeAndExecuteValidation(target, "pattern");

    // has equal:equal属性があった場合
    this._judgeAndExecuteValidation(target, "equal");

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * ラジオボタンルール
   * @param {DOM element} target
   */
  radio(target) {
    // required属性があった場合
    if (this.hasValidateAttribute(target, "required"))
      this.radioRequired(target);

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * チェックルール
   * @param {DOM element} target
   */
  check(target) {
    // required属性があった場合
    if (this.hasValidateAttribute(target, "required"))
      this.checkRequired(target);

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * セレクトルール
   * @param {DOM element} target
   */
  select(target) {
    // required属性があった場合
    if (this.hasValidateAttribute(target, "required"))
      this.selectRequired(target);

    // バリデーション結果をもとにvalid属性の値をセットする。
    this.setValidityValid(target);
  }

  /**
   * バリデート処理関数群
   * 定義したバリデートルールの中で使用する処理の一覧
  ------------------------------------- */
  /**
   * 必須項目が入力されているかをチェックする。（input）
   *
   * @param {DOM element} target
   * @returns {boolean}
   */
  required(target) {
    // 入力値がない場合、missingエラー
    if (target.value.length == 0) {
      // validityを作成しエラーを設定
      let validity = this.getValidity(target);
      validity.valueMissing = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 数字のみかどうかをチェックする。
   *
   * @param {DOM element} target
   * @returns {boolean}
   */
  number(target) {
    // 入力値が数字でない場合、badInputエラー
    if (isNaN(target.value) && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.notNumeric = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 半角英数記号のみ
   * @param {DOM element} target
   * @returns {boolean}
   */
  halfWidth(target) {
    // 入力値がマッチしない場合、patternMismatchエラー
    if (!target.value.match(/^[\x20-\x7e]+$/) && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.patternMismatch = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 最小数値
   * @param {DOM element} target
   * @returns {boolean}
   */
  min(target) {
    // 最小値を属性から取得
    const attr = this.getValidateAttribute(target, "min");

    // 値がなければ離脱
    if (typeof attr === "undefined") return true;

    // 入力値が小さい場合、rangeUnderflowエラー
    if (Number(target.value) < Number(attr) && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.rangeUnderflow = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 最大数値
   * @param {DOM element} target
   * @returns {boolean}
   */
  max(target) {
    // 最大値を属性から取得
    const attr = this.getValidateAttribute(target, "max");

    // 値がなければ離脱
    if (typeof attr === "undefined") return true;

    // 入力値が大きい場合、rangeOverflowエラー
    if (Number(target.value) >= Number(attr)) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.rangeOverflow = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 最小の文字列長
   * @param {DOM element} target
   * @returns {boolean}
   */
  minlength(target) {
    // 最短値を属性から取得
    const attr = this.getValidateAttribute(target, "minlength");

    // 値がなければ離脱
    if (typeof attr === "undefined") return false;

    // 入力値が短い場合、tooShortエラー
    if (target.value.length < attr && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.tooShort = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 最大の文字列長
   * @param {DOM element} target
   * @returns {boolean}
   */
  maxlength(target) {
    // 最長値を属性から取得
    const attr = this.getValidateAttribute(target, "maxlength");

    // 値がなければ離脱
    if (typeof attr === "undefined") return false;

    // 入力値が長い場合、tooLongエラー
    if (target.value.length > attr) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.tooLong = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 正規表現パターン
   * @param {DOM element} target
   * @returns {boolean}
   */
  pattern(target) {
    const attr = this.getValidateAttribute(target, "pattern");
    const pattern = RegExp(attr);

    // 入力値がマッチしない場合、patternMismatchエラー
    if (!target.value.match(pattern) && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.patternMismatch = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * メールアドレスパターン
   * @param {DOM element} target
   * @returns {boolean}
   */
  emailpattern(target) {
    const pattern = RegExp(".+@.+...+");

    // 入力値がマッチしない場合、mailMismatchエラー
    if (!target.value.match(pattern) && target.value.length > 0) {
      // validityを作成
      let validity = this.getValidity(target);

      validity.mailMismatch = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 必須項目（select）
   * @param {DOM element} target
   * @returns {boolean}
   */
  selectRequired(target) {
    const val = target.options[target.selectedIndex].value;

    // 入力値がデフォルトの場合、notSelectエラー
    if (val === "default" || val === "") {
      // validityを作成
      let validity = this.getValidity(target);

      validity.notSelect = true;

      // エラー状態を属性に反映
      this.setValidity(target, validity);

      return false;
    }
    return true;
  }

  /**
   * 必須項目（radio）
   * @param {DOM element} target
   * @returns {boolean}
   */
  radioRequired(target) {
    const _self = this;

    // 同じnameの要素をフィルター
    const name = target.getAttribute("name");
    const radioButtons = document.querySelectorAll(
      'input[type="radio"][name="' + name + '"]'
    );

    // チェック済みフラグ
    let validFlug = false;

    // チェック要素があるかを確認
    Array.from(radioButtons).forEach((element, i) => {
      // その要素がチェックされていた場合
      if (element.checked) validFlug = true;
    });

    Array.from(radioButtons).forEach((element, i) => {
      // validityを作成
      let validity = _self.getValidity(element);

      if (validFlug) {
        // ステータスを変更
        element.setAttribute(_self.super.ATTR_STATE, "valid");
        // エラーの詳細を設定
        validity.notCheck = false;
      } else {
        // ステータスを変更
        element.setAttribute(_self.super.ATTR_STATE, "invalid");
        // エラーの詳細を設定
        validity.notCheck = true;
      }

      // エラー状態を属性に反映
      _self.setValidity(element, validity);
    });

    if (validFlug) {
      return true;
    }
    return false;
  }

  /**
   * 必須項目（checkbox）
   * @param {DOM element} target
   * @returns {boolean}
   */
  checkRequired(target) {
    const _self = this;

    // 同じnameの要素をフィルター
    const name = target.getAttribute("name");
    const checkBoxes = document.querySelectorAll(
      'input[type="checkbox"][name="' + name + '"]'
    );

    // チェックフラグ
    let validFlug = false;

    // チェック要素があるかを確認
    Array.from(checkBoxes).forEach((element, i) => {
      // その要素がチェックされていた場合
      if (element.checked) validFlug = true;
    });

    Array.from(checkBoxes).forEach((element, i) => {
      // validityを作成
      let validity = _self.getValidity(element);

      if (validFlug) {
        // ステータスを変更
        element.setAttribute(_self.super.ATTR_STATE, "valid");
        // エラーの詳細を設定
        validity.notCheck = false;
      } else {
        // ステータスを変更
        element.setAttribute(_self.super.ATTR_STATE, "invalid");
        // エラーの詳細を設定
        validity.notCheck = true;
      }

      // エラー状態を属性に反映
      _self.setValidity(element, validity);
    });

    if (validFlug) {
      return true;
    }
    return false;
  }

  /**
   * 同じ値かどうか
   * @param {DOM element} target
   * @returns {boolean}
   */
  equal(target) {
    const _self = this;

    // nameを取得
    const name = _self.getValidateAttribute(target, "equal");

    // 他方の要素を取得
    const setInputs = document.querySelectorAll(
      "*[" + _self.super.PLUGIN_PREFIX + "-equal=" + name + "]"
    );
    const inputQuantity = Array.from(setInputs).length;

    // 要素が一つの場合は比較できないためtrueとして離脱
    if (inputQuantity == 1) return true;

    // バリデートが未完の要素があるかどうかをチェック
    var notValidated = Array.from(setInputs).filter((element) => {
      // ステータスを取得
      const state = element.getAttribute(_self.super.ATTR_STATE);

      // 未検証ステータスの要素を返送
      if (!_self.isValidatedItem(element)) return element;
    });

    // まだ一度も触れていない要素がある場合は検証出来ないためtrueとして離脱
    if (notValidated.length > 0) return true;

    // 対象要素全ての値を取得
    const values = Array.from(setInputs).map((element) => {
      return element.value;
    });
    // 全項目の値が一致しているかチェック
    let isAllEqual = values.every((value) => value === values[0]);

    // バリデート結果によって処理を実行
    if (isAllEqual) {
      // 全ての値が一致している場合
      // エラー状態を属性に反映
      Array.from(setInputs).forEach((element) => {
        // equal項目をクリアしたvalidityを作成
        let validity = _self.getValidity(element);
        validity.notEqual = false;

        // エラー状態を属性に反映
        _self.setValidity(element, validity);

        // エラー属性を除去
        _self.super.setState(element,'valid');
      });

      return true;
    } else {
      // 一致していない値がある場合

      Array.from(setInputs).forEach((element) => {
        // equal項目がエラーのvalidityを作成
        let validity = _self.getValidity(element);
        validity.notEqual = true;

        // 対象要素以外の場合、validをfalseに変更し、エラー属性の付与まで行う。
        if (!target.isEqualNode(element)) {
          validity.validity = false;
          // エラー属性を付与
          _self.super.setState(element, "invalid");
        }

        // エラー状態を属性に反映
        _self.setValidity(element, validity);
      });
      return false;
    }
  }

  /* ----------------------------------------------
  * サブルーチン
  ---------------------------------------------- */
  /**
   * ※private
   * 属性値の存在をチェックし、属性値があればバリデーションを実行する。
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @param {String} attr バリデーションを実行したい属性値
   */
  _judgeAndExecuteValidation(target, validProperty) {
    if (this.hasValidateAttribute(target, validProperty))
      this[validProperty](target);
  }

  /* 要素判定関連 ------------------------------------- */
  /**
   * 対象要素がバリデート可能かを判定
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @returns {Boolean}
   */
  isValidatableItem(target) {
    // HTML要素でなければ除外
    if (target instanceof HTMLElement === false) return false;

    // フォームパーツHTML以外を除外
    const tagName = target.tagName;
    const matchTags = ["INPUT", "TEXTAREA", "SELECT"];
    if (!matchTags.includes(tagName)) {
      return false;
    }

    // submit、resetタイプを除外
    const type = target.getAttribute("type");
    const matchType = ["submit", "reset"];
    if (matchType.includes(type)) return false;

    // すべての除外対象でなければバリデート対象とする。
    return true;
  }

  /**
   * 対象要素が一度でもバリデートされているかを判定
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @returns {Boolean}
   */
  isValidatedItem(target) {
    // validityを取得
    let validity = this.getValidity(target);

    // validityが空であれば一度もバリデートされていない項目と見なしfalseを返す
    if (Object.keys(validity).length == 0) return false;

    // validityがあればバリデート済みとしてtrueを返す。
    return true;
  }

  /* バリデーション用属性制御関連 ------------------------------------- */
  /**
   * バリデーション用属性の存在チェックする
   *
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @param {String} attr 存在確認を行いたい属性
   * @returns {Boolean}
   */
  hasValidateAttribute(target, attr) {
    // 対象がHTML要素でなければfalse
    if (target instanceof HTMLElement === false) return false;

    if (
      target.hasAttribute(attr) ||
      target.hasAttribute(this.super.PLUGIN_PREFIX + "-" + attr)
    ) {
      // 通常のフォーム規則、またはカスタム属性があったはバリデート属性ありと判別
      return true;
    } else {
      // どちらもなかった場合
      return false;
    }
  }

  /**
   * バリデーション用属性の値を取得
   * @param {DOM element} target 対象要素のDOMオブジェクト
   * @param {String} attr 取得したい属性
   * @returns {Boolean}
   */
  getValidateAttribute(target, attr) {
    // 対象がHTML要素でなければfalse
    if (target instanceof HTMLElement === false) return false;

    if (target.hasAttribute(attr)) {
      // 通常のフォーム規則があった場合
      return target.getAttribute(attr);
    } else if (target.hasAttribute(this.super.PLUGIN_PREFIX + "-" + attr)) {
      // カスタム属性があった場合
      return target.getAttribute(this.super.PLUGIN_PREFIX + "-" + attr);
    } else {
      return false;
    }
  }

  /* Validity属性制御関連 ------------------------------------- */
  /**
   * 対象要素のバリデート状態を取得
   * @param {DOM element} target バリデート対象のDOMオブジェクト
   * @returns {Object} 対象要素のバリデートステータス
   */
  getValidity(target) {
    // 属性値を取得
    const validityString = target.getAttribute(this.super.ATTR_VALIDITY);

    // JSON文字列をvalidityオブジェクトに変換
    // {"valid":false,"valueMissing":true} → Object
    let validity = JSON.parse(validityString);

    // 属性値がない場合は空配列を返す。
    if (validity == null) validity = {};

    return validity;
  }

  /**
   * 対象要素にバリデート状態をセット
   * @param {DOM element} target バリデート対象のDOMオブジェクト
   * @param {Object} validity 変更したいバリデートステータス
   * @returns -
   */
  setValidity(target, validity) {
    // validityをJSON文字列に変換
    // Object → {"valid":false,"valueMissing":true}
    const validityString = JSON.stringify(validity);

    // 属性値をセット
    target.setAttribute(this.super.ATTR_VALIDITY, validityString);
  }

  /**
   * 対象要素のバリデート状態を初期状態にリセット
   * @param {DOM element} target バリデート対象のDOMオブジェクト
   * @returns -
   */
  resetValidity(target) {
    // デフォルトのvalidityをJSON文字列に変換
    // Object → {"valid":false,"valueMissing":true}
    const validityString = JSON.stringify(this.validity);

    // 属性値をセット
    target.setAttribute(this.super.ATTR_VALIDITY, validityString);
  }

  /**
   * バリデート状態（validityのfalse状況）を、エラーを優先（＝エラーが上書きされない）してマージする。
   * @param {Array} validities
   * @returns {Object} validity マージされたバリデートステータス
   */
  mergeValidity(validities) {
    // デフォルトのvalidityをマージベースとしてセット
    let mergedValidity = Object.assign({}, this.validity);

    validities.forEach((validity) => {
      // validityの全項目に対して、マージを実行
      // ※デフォルトのvalidityのキーが基準になるためeachはデフォルト基準で実行
      Object.keys(mergedValidity).forEach((key) => {
        // valid属性は他の属性値の値をもって決定するのでスキップ
        if (key == "valid") return;

        // エラー項目があった場合
        if (validity[key] == true) {
          // エラーの項目が一つでもあればvalidをfalseに変更
          mergedValidity.valid = false;
          // validityの同項目をエラー状態に変更
          mergedValidity[key] = true;
        }
      });
    });

    return mergedValidity;
  }

  /**
   * validityの各項目のエラー状況を元に対象要素のvalid属性の判断を行う。
   * （※複数のvalidityにエラー項目がある際に、処理が後の項目での結果で全体がvalidになってしまうのを防ぐため。）
   *
   * @param {DOM element} target バリデート対象のDOMオブジェクト
   * @returns -
   */
  setValidityValid(target) {
    let validity = this.getValidity(target);

    // valid属性を初期化
    validity.valid = true;

    // validityの全項目に対して、エラーがあるかのチェックを実行
    Object.keys(validity).forEach((key) => {
      // valid属性は他の属性値の値をもって決定するのでスキップ
      if (key == "valid") return;

      // エラーの項目が一つでもあればvalidをfalseに変更
      if (validity[key] == true) validity.valid = false;
    });

    // エラー状態を属性に反映
    this.setValidity(target, validity);
  }

  /**
   * 対象要素のバリデート状態を消去する。
   * @param {DOM element} target バリデート対象のDOMオブジェクト
   * @returns -
   */
  destroyValidity(target) {
    // 属性を消去
    target.removeAttribute(this.super.ATTR_VALIDITY);
  }
}
