/* ----------------------------------------------
 * フォームバリデーションjs
 *
---------------------------------------------- */
// module exports
export { ParceFormQuery };

/* 定数
------------------------------------- */
class ParceFormQuery{
  errorFlug;
  queryStrings;

  constructor(){
    // フラグを初期化
    this.errorFlug = false;
    //
    this.parceUri();
  }

  /**
   * URIをパースしてクエリーをフォームに復帰、エラーフラグをセットする
   */
  parceUri(){
    // クエリーを処理
    const search = location.search;

    //クエリーがなければskip
    if (search.length > 0) {

      // クエリストリングをパース
      this.queryStrings = search
        .split("?")[1]
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});

      // フラグを初期化
      this.errorFlug = false;

      // errorsにtrueが入っていればエラーとみなす。
      if( this.queryStrings['errors']=='true' ){
        this.errorFlug = true;
      }

      // GETパラメータの配列を回し、同名項目があれば値を復帰
      Object.keys(this.queryStrings).forEach((key) => {
        const originValue = this.queryStrings[key];

        // 値をデコードする
        const value = unescape(decodeURI(originValue));

        // ターゲットを走査する。
        const target = document.querySelectorAll('*[name="' + key + '"]');

        // 同じnameの要素が存在する場合、値を展開する
        if (target.length > 0) {
          let type = target[0].getAttribute("type");
          let tagName = target[0].tagName;

          switch (tagName) {
            case "INPUT":
              if (type === "checkbox" || type === "radio") {
                target.forEach((element) => {
                  if (element.value == value) element.checked = true;
                });
              }
              if (type === "text" || type === "number" || type === "email" || type === "tel" || type === "hidden") {
                target[0].value = value;
              }
              break;

            case "SELECT":
              target[0].value = value;
              break;

            case "TEXTAREA":
              target[0].value = value;
              break;
          }
        }
      });
    }
  }

  /**
   * pardotエラーが有ったかどうかのチェッカー
   * @returns {Boolean} Pardotのエラーがあったかどうかの真偽値
   */
  hasPardotError(){
    return this.errorFlug;
  }
}